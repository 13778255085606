import { Club } from "../types";
import useSubscriptionModalHook from "../hooks/use-subscription-modal-hook";

export default function ClubListCard(club: Club) {
  const { additionalText, price, exceptionText, services, name, description, isBestSeller } = club;

  const { showSubscriptionModal } = useSubscriptionModalHook({ club });

  return (
    <div className="club-list-card">
      {isBestSeller && (
        <img src="./assets/icon-mais-vendido.png" className="club-list-card-best-seller-icon" alt="Mais vendido" />
      )}
      <div className="club-list-card-header">
        <span className="club-list-card-header-prefix">Clube</span>
        <span className="club-list-card-header-title">{name}</span>
        <span className="club-list-card-header-description">{description}</span>
        <span className="club-list-card-header-price">
          {additionalText && <span className="club-list-card-header-price-description">{additionalText}</span>}
          <span className="club-list-card-header-price-currency">R$</span>
          <span className="club-list-card-header-price-value">{price}</span>
          <span className="club-list-card-header-price-period">/mês{exceptionText && '*'}</span>
          {exceptionText && <span className="club-list-card-header-price-exceptions">*{exceptionText}</span>}
        </span>
      </div>
      <div className="club-list-card-content">
        <ul>
          {services.map((item) => <li key={item}>{item}</li>)}
        </ul>

        <button
          className="club-list-card-button"
          onClick={showSubscriptionModal}
          rel="noreferrer"
        >
          Assinar
        </button>
      </div>
    </div>
  );
}
