type PaymentLink = {
  id: string;
  url: string;
}

export default function getPaymentLink(planId: string): Promise<PaymentLink> {
  const options = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: `Basic ${btoa(process.env.REACT_APP_API_KEY || '')}`,
    },
    body: JSON.stringify({
      type: "subscription",
      payment_settings: {
          accepted_payment_methods: [
            "CREDIT_CARD",
            "BOLETO"
          ],
          statement_descriptor: "Clair de Lune",
          credit_card_settings: {
            operation_type: "auth_and_capture"
          },
          boleto_settings: {
            due_in: 7,
            instructions: "Sr. Caixa, por favor, não aceitar o pagamento após o vencimento."
          }
      },
      cart_settings: {
        recurrences: [
          {
            plan_id: planId,
            boleto_due_days: 7
          }
        ]
      }
    })
  };

  const url = process.env.REACT_APP_PAYMENT_LINK_API || '';

  return fetch(url, options)
    .then(res => res.json())
    .catch(err => console.error(err));
}
