import { Club } from "../types";

export default function getNailClubs(): Club[] {
  return [
    {
      id: process.env.REACT_APP_CLUB_ID_LUA_NOVA,
      hasException: false,
      description: 'Para iniciar sua jornada de autoconfiança e beleza',
      name: 'Lua Nova',
      price: '64,90',
      services: [
        '4 serviços de manicure mensais',
        'Preços exclusivos em outros serviços',
        'Renovação mensal',
      ],
      type: 'nail',
    },
    {
      id: process.env.REACT_APP_CLUB_ID_LUZ_DO_ENCANTO,
      hasException: false,
      isBestSeller: true,
      description: 'Deixe sua beleza brilhar com uma rotina de cuidados exclusivos',
      name: 'Luz do Encanto',
      price: '99,90',
      services: [
        '2 serviços de manicure mensais',
        '2 serviços de pé e mão mensais',
        'Preços exclusivos em outros serviços',
        'Renovação mensal',
      ],
      type: 'nail',
    },
    {
      id: process.env.REACT_APP_CLUB_ID_LUA_DE_CRISTAL,
      hasException: false,
      description: 'Assim como Cinderela tinha seu sapatinho de cristal, seus pés também merecem brilhar',
      name: 'Lua de Cristal',
      price: '99,90',
      services: [
        '3 serviços de pedicure mensais',
        '1 spa dos pés mensal',
        'Preços exclusivos em outros serviços',
        'Renovação mensal',
      ],
      type: 'nail',
    },
    {
      id: process.env.REACT_APP_CLUB_ID_MULHER_DE_FASES,
      hasException: false,
      description: 'Liberdade para viver suas melhores fases',
      name: 'Mulher de Fases',
      price: '139,90',
      services: [
        '4 serviços de pé e mão mensais',
        'Preços exclusivos em outros serviços',
        'Renovação mensal',
      ],
      type: 'nail',
    },
  ];
}
