import { Link, Outlet, useLocation } from 'react-router-dom';
import isMobile from './tools/is-mobile.tool';

export default function Layout() {
  const location = useLocation();
  const isAllVisible = !location.pathname.includes('checkout');

  return (
    <div style={{ height: '100%' }}>
      <nav className="menu">
        <Link to="/">
          <img src="./assets/logo-horizontal-320.png" alt="Clair de Lune Salão de Beleza" />
        </Link>
        {isAllVisible && (
          <ul className="menu-list">
              <>
                <li className="menu-item">
                  {isMobile() ? (
                    <a href="https://www.trinks.com/clair-de-lune/">
                      Agendar
                    </a>
                  ) : (
                    <Link to="/agendar">
                      Agendar
                    </Link>
                  )}
                </li>
                <li className="menu-item">
                  <Link to="/#clubes">
                    Clube
                  </Link>
                </li>
              </>
            <li className="menu-item menu-item-icon">
              <a
                href="https://www.facebook.com/people/Clair-de-Lune/61560906628518/"
                target="_blank" rel="noreferrer"
              >
                <img src="./assets/icon-facebook.svg" alt="Facebook" />
              </a>
            </li>
            <li className="menu-item menu-item-icon">
              <a
                href="https://instagram.com/salaoclairdelune"
                target="_blank" rel="noreferrer"
              >
                <img src="./assets/icon-instagram.svg" alt="Instagram" />
              </a>
            </li>
            <li className="menu-item menu-item-icon">
              <a
                href="https://wa.me/5521986841208"
                target="_blank" rel="noreferrer"
              >
                <img src="./assets/icon-whatsapp.svg" alt="WhatsApp" />
              </a>
            </li>
          </ul>
        )}
      </nav>

      <Outlet />
    </div>
  );
}
