import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import Layout from './Layout';
import Home from './routes/Home';
import Schedules from './routes/Schedules';
import Error from './routes/Error';
import Article from './routes/Article';
import Checkout from './routes/Checkout';
import useScrollToAnchor from './hooks/scroll-to-anchor.hook';
import Modal, { ModalData, initialModalData } from './components/Modal';
import { MainContext } from './contexts/main.context';

import 'normalize.css';
import './App.css';

export default function App() {
  const [showChat, setShowChat] = useState(false);
  const [showChatText, setShowChatText] = useState(true);
  const [mainContext, setMainContext] = useState({
    modalData: initialModalData as ModalData,
    isLoading: false,
    setMainContext: setMainContextCallback,
  });

  function setMainContextCallback(newContext: { modalData?: ModalData, isLoading?: boolean }) {
    setMainContext((currentMainContext) => ({ ...currentMainContext, ...newContext }));
  }

  useScrollToAnchor();

  useEffect(() => {
    const chat = setTimeout(() => {
      setShowChat(true);
    }, 5_000);

    const chatText = setTimeout(() => {
      setShowChatText(false);
    }, 15_000);

    return () => {
      clearTimeout(chat);
      clearTimeout(chatText);
    }
  }, []);

  return (
    <MainContext.Provider value={mainContext}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/agendar" element={<Schedules />} />
          <Route path="/checkout/:clubId" element={<Checkout />} />
          <Route path="/artigo/:articleName" element={<Article />} />
          <Route path="/erro" element={<Error />} />

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<Error />} />
        </Route>
      </Routes>
      <Modal
        isVisible={mainContext.modalData.isVisible}
        text={mainContext.modalData.text}
        onClose={mainContext.modalData.onClose}
        buttons={mainContext.modalData.buttons}
        JSXContent={mainContext.modalData.JSXContent}
      />
      {showChat && (
        <a
          href="https://wa.me/5521986841208?text=Olá!%20Vim%20pelo%20site%20e%20gostaria%20de%20tirar%20dúvidas!"
          target="_blank" rel="noreferrer"
          className="home-chat"
        >
          {showChatText && <span className="home-chat-text">Ficou com alguma dúvida? Fale conosco!</span>}
          <img src="./assets/icon-whatsapp.svg" alt="WhatsApp" />
        </a>
      )}
      {mainContext.isLoading && (
        <div className="loading-container">
          <img src="./assets/loading-icon.gif" alt="Carregando..." className="loading-icon" />
        </div>
      )}
    </MainContext.Provider>
  );
}
