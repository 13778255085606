import ClubListCard from "./ClubListCard";
import { Club } from "../types";

type ClubListTypes = {
  cards: Club[];
  title?: string;
}

export default function ClubList({ cards, title }: ClubListTypes) {
  return (
    <>
    {title && <div className="club-list-title">{title}</div>}
    <div className="club-list">
      <div className="club-list-container">
        {cards.map((card) => (
          <ClubListCard
            additionalText={card.additionalText}
            description={card.description}
            exceptionId={card.exceptionId}
            exceptionPrice={card.exceptionPrice}
            exceptionText={card.exceptionText}
            hasException={card.hasException}
            id={card.id}
            isBestSeller={card.isBestSeller}
            key={card.id}
            name={card.name}
            price={card.price}
            services={card.services}
            type={card.type}
          />
        ))}
        </div>
    </div>
    </>
  );
}
