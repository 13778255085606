import { Club } from "../types";

export default function getHairClubs(): Club[] {
  return [
    {
      id: process.env.REACT_APP_CLUB_ID_CRESCENTE_PODEROSA_DEFAULT,
      exceptionId: process.env.REACT_APP_CLUB_ID_CRESCENTE_PODEROSA_MEGAHAIR,
      hasException: true,
      additionalText: 'a partir de',
      description: 'Hidrate e garanta cabelos brilhosos semanalmente',
      name: 'Crescente Poderosa',
      price: '159,90',
      exceptionPrice: '209,90',
      exceptionText: 'R$209,90 para clientes com mega hair a partir de 200g',
      services: [
        '4 serviços de hidratação + escova',
        'Preços exclusivos em outros serviços',
        'Renovação mensal'
      ],
      type: 'hair',
    },
    {
      id: process.env.REACT_APP_CLUB_ID_CHEIA_DE_FORCA_DEFAULT,
      exceptionId: process.env.REACT_APP_CLUB_ID_CHEIA_DE_FORCA_MEGAHAIR,
      hasException: true,
      isBestSeller: true,
      additionalText: 'a partir de',
      description: 'Desperte o máximo potencial da força e vitalidade da beleza de seus cabelos',
      name: 'Cheia de Força',
      price: '259,90',
      exceptionPrice: '329,90',
      exceptionText: 'R$329,90 para clientes com mega hair a partir de 200g',
      services: [
        '4 tratamentos BRAÉ / Wella + escova mensais',
        'Preços exclusivos em outros serviços',
        'Renovação mensal'
      ],
      type: 'hair',
    },
  ];
}
