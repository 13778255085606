import { useContext, useState } from "react";
import { MainContext } from "../contexts/main.context";
import { initialModalData } from "../components/Modal";
import getPaymentLink from "../services/get-payment-link";

type UseClubRedirectToPaymentHookOptions = {
  isValid?: boolean;
}

type UseClubRedirectToPaymentHookReturn = {
  redirectToPayment: (clubId: string) => void;
  clicked: boolean;
}

export default function useClubRedirectToPaymentHook(options?: UseClubRedirectToPaymentHookOptions): UseClubRedirectToPaymentHookReturn {
  const { isValid } = options || {};
  const [clicked, setClicked] = useState(false);
  const { setMainContext } = useContext(MainContext);

  function resetModalData() {
    setMainContext?.({ modalData: initialModalData })
  }

  function setModalWithError(error: string) {
    console.error(error);

    setMainContext?.({
      isLoading: false,
      modalData: {
        text: error,
        isVisible: true,
        onClose: resetModalData,
        buttons: [
          {
            id: 0,
            text: 'Entrar em contato via WhatsApp',
            action: () => {
              window.location.href = 'https://wa.me/5521986841208';
            },
          },
        ],
      },
    });
  }

  async function redirectToPaymentCallback(clubId: string) {
    try {
      setMainContext?.({ isLoading: true });

      const paymentLink = await getPaymentLink(clubId);
      if (paymentLink?.url) {
        window.location.href = paymentLink.url;
      } else {
        throw new Error('Erro ao obter o link de pagamento');
      }

      setMainContext?.({ isLoading: false });
    } catch (error) {
      setModalWithError('Erro ao obter o link de pagamento');
    }
  }

  async function redirectToPayment(clubId: string) {
    if (isValid !== undefined && !isValid) {
      setClicked(true);
      console.info('FORMULÁRIO INVÁLIDO, PREENCHA TODAS AS INFORMAÇÕES OBRIGATÓRIAS');
      return;
    }

    redirectToPaymentCallback(clubId);
  }

  return ({
    redirectToPayment,
    clicked,
  });
}
